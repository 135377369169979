import { useState, useEffect, useContext } from 'react';
import { Leaderboard, NotificationContext } from '@mdc/ui';
import { QueryParams, CDNService, SERVICE_ERRORS, logService, InternalNavigation } from '@mdc/services';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const GenericLeaderboard = ({ folder, titleKey, descriptionKey, color, processLeaderboard }) => {
    const [availableDates, setAvailableDates] = useState([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const { notify } = useContext(NotificationContext);
    const { t, ready } = useTranslation();
    const { date } = QueryParams();

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            const onCDNerror = (data) => {
                logService.log(data);
                notify({
                    message: t('The leaderboard data you are trying to view is not accessible. Please make sure you have the correct link and try again'),
                    type: NOTIFICATION_TYPES.CRITICAL,
                });
            };

            const onCDNinvalid = () => {
                notify({
                    message: t('There was an issue loading the report. Please try again later'),
                    type: NOTIFICATION_TYPES.CRITICAL,
                });
            };

            const handleCDNnavigate = (path) => {
                InternalNavigation.navigation(path);
            };

            const updateDate = async (fileName) => {
                if (!fileName) {
                    return [];
                }

                try {
                    const updatedReport = await CDNService.getCDNReport(`${fileName}.json`, folder, handleCDNnavigate);
                    return updatedReport?.leaderboard ?? [];
                } catch (error) {
                    logService.error(error);
                    return [];
                }
            };

            try {
                CDNService.on(SERVICE_ERRORS.REPORT_ERROR, onCDNerror);
                CDNService.on(SERVICE_ERRORS.INVALID_REPORT, onCDNinvalid);

                const oldReports = await CDNService.getCDNReport('older-reports.json', folder, handleCDNnavigate);
                const latest = await CDNService.getCDNReport('latest.json', folder, handleCDNnavigate);

                if (Array.isArray(oldReports)) {
                    setAvailableDates(oldReports);
                }

                if (latest?.leaderboard && Array.isArray(latest.leaderboard)) {
                    if (date?.toLowerCase() === 'latest') {
                        setLeaderboard(latest.leaderboard);
                    } else {
                        const updatedLeaderboard = await updateDate(date);
                        setLeaderboard(updatedLeaderboard);
                    }
                }
            } catch (error) {
                logService.error(error);
            }
        };

        fetchLeaderboardData();
    }, [date, folder, notify, t]);

    const processedLeaderboard = processLeaderboard ? processLeaderboard(leaderboard) : leaderboard;

    if (!ready) {
        return null;
    }

    return (
        <Leaderboard
            availableDates={availableDates}
            leaderboard={processedLeaderboard}
            title={t(titleKey)}
            description={t(descriptionKey)}
            color={color}
        />
    );
};

GenericLeaderboard.propTypes = {
    folder: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    processLeaderboard: PropTypes.func,
};

export default GenericLeaderboard;
