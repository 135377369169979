import GenericLeaderboard from '../GenericLeaderboard';

const TopVoters = () => {
    const processLeaderboard = (leaderboard) =>
        leaderboard.map(({ votes, ...rest }) => ({
            ...rest,
            score: votes ?? rest.score,
        }));

    return (
        <GenericLeaderboard
            folder='reports/leaderboard-voters/'
            titleKey='Top Voters'
            descriptionKey='Leaderboard generated from user votes on all files'
            color='green'
            processLeaderboard={processLeaderboard}
        />
    );
};

export default TopVoters;
