import GenericLeaderboard from '../GenericLeaderboard';


import './TopMalwareSubmitters.scss';

const TopMalwareSubmitters = () => {
    return (
        <GenericLeaderboard
            folder='reports/leaderboard/'
            titleKey='Top Malware Submitters'
            descriptionKey='Leaderboard generated from submissions by free users'
            color='blue'
        />
    );
};

export default TopMalwareSubmitters;
